import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationStart, Router } from '@angular/router';
import { AnalyticsEvent } from '@core/models/analyticsEvent';
import { AnalyticsService } from '@core/services/common/analytics.service';
import { createDialogConfig } from '@globalHelpers/dialog';
import { Store } from '@ngrx/store';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { AddressResult } from '@shared/components/molecules/json-search/models/json-search';
import { LOGO_IMG } from '@shared/constants/images';
import { FormValidationService } from '@shared/services/form-validation.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  createUserAccountConfirmation,
  createWarehouse,
  resendConfirmationEmail,
  storeWarehouse,
} from '../../../actions/authentication';
import { PROVINCES_DROPDOWN_DATA } from '../../../constants/business-info';
import {
  Address,
  BusinessProfile,
  DropDownItem,
  UserAccountStatus,
  Warehouse,
} from '../../../models/authentication.models';
import { InitialState } from '../../../reducers/authentication';
import {
  accountConfirmationStatus,
  businessProfile,
  createWarehouseInProgress,
  userEmail,
  warehouse,
} from '../../../selectors/authentication';

@Component({
  selector: 'app-create-warehouse',
  templateUrl: './create-warehouse.component.html',
  styleUrls: ['./../business-info.scss', './create-warehouse.component.scss'],
})
export class CreateWarehousePageComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  submitAttempted = false;
  selectedProvince: DropDownItem;
  provinces = PROVINCES_DROPDOWN_DATA;
  createWarehouseInProgress$: Observable<boolean> = this.store.select(createWarehouseInProgress);
  businessProfile$: Observable<any> = this.store.select(businessProfile);
  warehouse$: Observable<any> = this.store.select(warehouse);
  subscriptions: Subscription[] = [];
  suburb: string;
  accountConfirmationStatus$: Observable<UserAccountStatus> =
    this.store.select(accountConfirmationStatus);

  @ViewChild('postalCode', { read: ElementRef }) postalCode: ElementRef;
  @ViewChild('cityOrTown', { read: ElementRef }) cityOrTown: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formValidationService: FormValidationService,
    private store: Store<InitialState>,
    private router: Router,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
  ) {
    this.form = this.formBuilder.group({
      wareHouseContactPerson: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      warehouseEmailAddress: [
        '',
        [Validators.required, this.formValidationService.email, Validators.maxLength(255)],
      ],
      warehousePhoneNumber: ['', [Validators.required, this.formValidationService.phoneNumber]],
      unit: [
        '',
        [Validators.required, Validators.maxLength(255), this.formValidationService.isWhiteSpace],
      ],
      street: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      postalCode: [
        { value: '', disabled: true },
        [Validators.required, this.formValidationService.postalCode],
      ],
      cityOrTown: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.updateFormWithStoredData();
    this.updateWarehouseAddressFromProfile();
    this.showDialogOnAccountConfirmationRequired();
    this.monitorManualPageNavigation();
    const event = new AnalyticsEvent(
      'signup',
      'view',
      'create_warehouse_view',
      'in_progress',
      5,
      10,
    );
    this.analyticsService.logEvent(event);
  }

  monitorManualPageNavigation(): void {
    const routerSubscription = this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate' && event.url === '/create-profile') {
        this.storeCurrentFormData();
      }
    });
    this.subscriptions.push(routerSubscription);
  }

  onSubmit(): void {
    this.submitAttempted = true;
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    if (!this.selectedProvince) return;

    if (!this.suburb) return;

    const warehouseData = this.getWarehouseData();
    this.store.dispatch(createWarehouse({ warehouseData: warehouseData }));
    this.store.dispatch(
      createUserAccountConfirmation({ accountConfirmationStatus: UserAccountStatus.Unknown }),
    );
  }

  goBack(): void {
    this.storeCurrentFormData();
    this.router.navigate(['/create-profile']);
  }

  storeCurrentFormData(): void {
    const warehouseData = this.getWarehouseData();
    this.store.dispatch(storeWarehouse({ warehouseData: warehouseData }));
  }

  getWarehouseData(): Warehouse {
    return {
      wareHouseContactPerson: this.form.controls.wareHouseContactPerson.value,
      warehouseEmailAddress: this.form.controls.warehouseEmailAddress.value,
      warehousePhoneNumber: this.form.controls.warehousePhoneNumber.value,
      address: {
        unit: this.form.controls.unit.value,
        street: this.form.controls.street.value,
        suburb: this.suburb,
        postalCode: this.form.controls.postalCode.value,
        cityOrTown: this.form.controls.cityOrTown.value,
        province: this.selectedProvince?.value,
      },
    };
  }

  setDropdownValue(option: DropDownItem): void {
    this.selectedProvince = option;
  }

  updateFormWithStoredData(): void {
    const warehouseSubscription: Subscription = this.warehouse$
      .pipe(take(1))
      .subscribe((warehouseData: Warehouse) => {
        if (warehouseData) {
          this.form.patchValue({
            wareHouseContactPerson: warehouseData.wareHouseContactPerson,
            warehouseEmailAddress: warehouseData.warehouseEmailAddress,
            warehousePhoneNumber: warehouseData.warehousePhoneNumber,
          });
          this.setFormAddress(warehouseData.address);
          if (warehouseData.address?.province) {
            this.selectedProvince = {
              label: warehouseData.address.province,
              value: warehouseData.address.province,
            };
          }
        }
      });
    this.subscriptions.push(warehouseSubscription);
  }

  updateWarehouseAddressFromProfile(): void {
    const businessProfileSubscription: Subscription = this.businessProfile$.subscribe(
      (profile: BusinessProfile) => {
        if (profile?.sameShippingAddress) {
          this.setFormAddress(profile.address);
          if (profile.address?.province) {
            this.selectedProvince = {
              label: profile.address.province,
              value: profile.address.province,
            };
          }
        }
      },
    );
    this.subscriptions.push(businessProfileSubscription);
  }

  setFormAddress(address: Address) {
    this.form.patchValue({
      unit: address?.unit,
      street: address?.street,
      postalCode: address?.postalCode,
      cityOrTown: address?.cityOrTown,
    });
    this.suburb = address?.suburb;
  }

  showDialogOnAccountConfirmationRequired(): void {
    const accountConfirmationSubscription: Subscription = this.accountConfirmationStatus$.subscribe(
      (status: UserAccountStatus) => {
        if (status === UserAccountStatus.Unconfirmed) {
          const event = new AnalyticsEvent(
            'signup',
            'view',
            'create-warehouse_dialog_view',
            'in_progress',
            5,
            13,
          );
          this.analyticsService.logEvent(event);
          this.openAccountConfirmationDialog();
        }
      },
    );
    this.subscriptions.push(accountConfirmationSubscription);
  }

  openAccountConfirmationDialog(): void {
    const btnLabel = 'Resend Email';
    const heading = 'Verify E-mail Address';
    const text =
      'In order to use Pargo’s systems you need to verify your email address.<br/><br/>Please verify your email address by following the prompts in the email that Pargo sent you when signing up.';

    const dialogConfig = createDialogConfig(btnLabel, heading, text, LOGO_IMG);
    const dialogRef = this.dialog.open(InfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((action: string) => {
      if (action === 'Resend Email') {
        const email$: Observable<string> = this.store.select(userEmail);
        const emailSubscription: Subscription = email$.subscribe((email: string) => {
          this.store.dispatch(resendConfirmationEmail({ email }));
        });
        emailSubscription.unsubscribe();
      }
    });
  }

  setAddress(address: AddressResult) {
    this.suburb = address.suburb;
    if (address.city) {
      this.form.get('cityOrTown').setValue(address.city);
      this.cityOrTown.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      this.form.get('cityOrTown').disable();
    } else {
      this.form.get('cityOrTown').enable();
    }

    if (address.postalCode) {
      this.form.get('postalCode').setValue(address.postalCode);
      this.postalCode.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      this.form.get('postalCode').disable();
    } else {
      this.form.get('postalCode').enable();
    }
    if (address.province) {
      this.selectedProvince = {
        label: address.province,
        value: address.province,
      };
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
